import React from 'react';
import { times } from 'ramda';
import Slider from 'react-slick';
import SEO from './seo';
import Contact from './Contact';
import LocalizedTag from '../i18n/LocalizedTag';
import withIntl from '../i18n/withIntl';
import HideableHeader from './HideableHeader';
import TopicCard from './TopicCard';
import facebookLogo from '../../assets/images/facebook-logo-black.svg';
import linkedinLogo from '../../assets/images/linkedin-logo-black.svg';
import pdfForCompanies from '../../static/download/Nabidka_skoleni_firmy.pdf';
import '../../assets/styles/Web.scss';
import pdfLogo from '../../assets/images/pdf-download.png';
import conditions from '../../static/download/Obchodni_podminky.pdf';
import faq from '../../static/download/Caste_dotazy.pdf';

const numOfReasons = 10;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const TopicDetail = ({ pageContext }) => {
  const filteredReferences = [
    pageContext.topicReference1,
    pageContext.topicReference2,
    pageContext.topicReference3,
  ].filter((ref) => ref !== null && ref !== undefined && ref !== '');
  const randomReference = filteredReferences[Math.floor(Math.random() * filteredReferences.length)];

  const pdfDownload = (file, text) => (
    <a href={file} className="TopicDetail-downloadLink" download>
      <img src={pdfLogo} alt="logo of pdf" className="TopicDetail-pdfLogo" />
      <LocalizedTag id={text} type="h3" className="TopicDetail-pdfText" />
    </a>
  );

  return (
    <body>
      <SEO />
      <header className="TopicDetail">
        <HideableHeader hideLanguageSelect />
        {/* hide language select on TopicDetail component until SQN have courses in english as well */}
        <div className="TopicDetail-main">
          <h3 className="TopicDetail-motto">{pageContext.topicMotto}</h3>
          <br />
          <h1 className="TopicDetail-mainTitle">{pageContext.topicName}</h1>
          <br />
          <small className="TopicDetail-descriptionWhite TopicDetail-lineBreak">
            {pageContext.topicShortDescription}
          </small>
        </div>
      </header>
      <main className="TopicDetail-body">
        <div className="TopicDetail-topicLogoWrapper">
          <img
            src={pageContext.topicLogo}
            alt="logo of workshop topic"
            className="TopicDetail-topicLogo"
          />
        </div>
        <div className="TopicDetail-colWrapper">
          <div className="TopicDetail-leftCol">
            <div className="TopicDetail-textBox">
              <LocalizedTag id="courseContent" type="h3" className="TopicDetail-name" />
              <small className="TopicDetail-descriptionBlack TopicDetail-lineBreak">
                {pageContext.topicDescription}
              </small>
            </div>
            <div className="TopicDetail-textBox">
              <LocalizedTag id="courseSchedule" type="h3" className="TopicDetail-name" />
              <small className="TopicDetail-descriptionBlack TopicDetail-lineBreak">
                {pageContext.topicScheduleInfo}
              </small>
              <div className="TopicDetail-schedule">
                <TopicCard allWorkshops={pageContext.workshops} topic={pageContext} scheduleOnly />
              </div>
            </div>
            <div className="TopicDetail-textBox">
              <h3 className="TopicDetail-name">{pageContext.topicSpeakerName}</h3>
              <div className="TopicDetail-row">
                <img
                  src={pageContext.topicSpeakerPhoto}
                  alt="topic speaker"
                  className="TopicDetail-speakerPhoto"
                />
                <div className="TopicDetail-speakerBioCol">
                  <small className="TopicDetail-descriptionBlack TopicDetail-lineBreak">
                    {pageContext.topicSpeakerBio}
                  </small>
                  <div className="TopicDetail-iconRow">
                    <div className="TopicDetail-iconRow2">
                      {pageContext.topicSpeakerFB && (
                        <a href={`${pageContext.topicSpeakerFB}`}>
                          <img
                            src={facebookLogo}
                            alt="topic speaker"
                            className="TopicDetail-icon"
                          />
                        </a>
                      )}
                      {pageContext.topicSpeakerLinkedIn && (
                        <a href={`${pageContext.topicSpeakerLinkedIn}`}>
                          <img
                            src={linkedinLogo}
                            alt="topic speaker"
                            className="TopicDetail-icon"
                          />
                        </a>
                      )}
                    </div>
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="TopicDetail-rightCol">
            <div className="TopicDetail-referenceTitleBox">
              <LocalizedTag id="courseReference" type="h3" className="TopicDetail-name" />
            </div>
            <small className="TopicDetail-descriptionBlack TopicDetail-lineBreak">
              <i>{randomReference}</i>
            </small>
            <a href={pdfForCompanies} download className="TopicDetail-downloadLink">
              <div className="TopicDetail-referenceAd">
                <LocalizedTag id="courseAd" type="h3" className="TopicDetail-nameWhite" />
              </div>
            </a>
            <div className="TopicDetail-textBoxWithSlider TopicDetail-centeredText TopicDetail--slick">
              <LocalizedTag id="courseWhySQNtitle" type="h3" className="TopicDetail-mottoBlack" />
              <Slider {...sliderSettings}>
                {times(
                  (val) => (
                    <div className="TopicDetail-sliderTextBox" key={val}>
                      <LocalizedTag
                        className="TopicDetail-sliderText"
                        type="p"
                        id={`reasonText${val}`}
                      />
                    </div>
                  ),
                  numOfReasons
                )}
              </Slider>
            </div>
            <div className="TopicDetail-pdfCol">
              {pdfDownload(faq, 'faqTopicDetail')}
              {pdfDownload(conditions, 'termsAndConditions')}
            </div>
          </div>
        </div>

        <LocalizedTag id="workshopsTerms" type="h3" className="TopicDetail-title" />
        <div className="TopicDetail-topicCard">
          <TopicCard allWorkshops={pageContext.workshops} topic={pageContext} isDetail />
        </div>
      </main>
      <Contact />
    </body>
  );
};

export default withIntl(TopicDetail);
